import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Debug from "../components/Debug";

import {
   SignInButton,
   SignOutButton,
   SignedIn,
   SignedOut,
   UserButton,
} from "@clerk/clerk-react";

import logo from "../images/pdlogo.png";

// THEME
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "../routes/application/theme.js";

function AuthWrapper(props) {
   const navigate = useNavigate();
   const headerRef = useRef(null);

   const [headerHeight, setHeaderHeight] = useState(null);

   useEffect(() => {
      setHeaderHeight(headerRef.current.offsetHeight);
   }, [headerRef.current]);

   return (
      <ThemeProvider theme={theme}>
         <CssBaseline />

         {/* <Debug /> */}

         <Box
            sx={{
               px: 8,
               maxWidth: props.maxWidth,
               margin: "0 auto",
               overflowY: "auto",
            }}
            className='pdBkgnd'
         >
            <Grid
               container
               sx={{ py: 4 }}
               alignItems='space-between'
               ref={headerRef}
            >
               <Grid item xs={3}>
                  <Stack direction='row' spacing={6} alignItems='center'>
                     <Button onClick={() => navigate("/")} sx={{ ml: -1 }}>
                        <img src={logo} width='100' />
                     </Button>
                  </Stack>
               </Grid>
               <Grid item xs={9} sx={{ textAlign: "right" }}>
                  <Stack spacing={2} direction='row' justifyContent='flex-end'>
                     <SignedIn>
                        <Button
                           variant='outlined'
                           onClick={() => navigate("/editor")}
                        >
                           Events Dashboard
                        </Button>
                        {/* <SignOutButton>
                           <Button variant='outlined'>Sign Out</Button>
                        </SignOutButton> */}
                     </SignedIn>

                     <SignedOut>
                        <SignInButton>
                           <Button variant='outlined'>Sign In</Button>
                        </SignInButton>
                     </SignedOut>

                     <UserButton />
                  </Stack>
               </Grid>
            </Grid>

            <Box
               sx={{
                  position: "relative",
                  height: `calc(100vh - ${headerHeight + 100}px)`,
               }}
            >
               {props.children}
            </Box>
         </Box>
      </ThemeProvider>
   );
}

export default AuthWrapper;
