import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { motion } from "framer-motion";

import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";

function ElementContainer({ elementIndex, element, names }) {
   return (
      <>
         {element["element"].type == "name" && (
            <Stack
               sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  color: "#000",
                  zIndex: 100,
               }}
               alignItems={element.text.alignItems}
               justifyContent={element.text.justifyContent}
            >
               <Box
                  sx={{
                     pl: element.text.px,
                     fontSize: element.text.titleFontSize,
                     lineHeight: element.text.titleLineHeight,
                     fontFamily: element.text.titleFontFamily,
                     fontWeight: element.text.titleFontWeight,
                     fontStyle: element.text.titleFontStyle,
                     color: element.text.titleColor,
                  }}
               >
                  {names && names[elementIndex]
                     ? names[elementIndex].title
                     : "Sample Name"}
               </Box>
               <Box
                  sx={{
                     pl: element.text.px,
                     fontSize: element.text.descriptionFontSize,
                     lineHeight: element.text.descriptionLineHeight,
                     fontFamily: element.text.descriptionFontFamily,
                     fontWeight: element.text.descriptionFontWeight,
                     fontStyle: element.text.descriptionFontStyle,
                     color: element.text.descriptionColor,
                  }}
               >
                  {names && names[elementIndex]
                     ? names[elementIndex].description
                     : "Sample Description"}
               </Box>
            </Stack>
         )}

         <Box
            sx={{
               position: "absolute",
               top: 0,
               left: 0,
               width: "100%",
               height: "100%",
               background: `url(${element.image.src}) center right`,
               backgroundRepeat: "no-repeat",
               backgroundSize: element.image.size,
               transform: `scale(${element.image.scale})`,
            }}
         ></Box>
      </>
   );
}

export default function LayoutElement({ element }) {
   return (
      <>
         <div
            style={{
               position: "absolute",
               zIndex: element.style.zIndex,
               outline: "40px solid transparent",
               overflow: "hidden",
               backgroundColor: element.color.backgroundColor,

               left: 0,
               top: 0,
               width: "100%",
               height: "100%",
               opacity: element.animate.opacity,
               borderRadius: element.animate.borderRadius,
               filter: `blur(${element.animate.blur}px)`,
            }}
            className='element'
         >
            <ElementContainer element={element} />
         </div>
      </>
   );
}
