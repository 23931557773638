import { useState, useContext, useMemo, useEffect } from "react";
import { useAuth } from "@clerk/clerk-react";
import { useParams } from "react-router";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useApp() {
   const { entryId } = useParams();
   const { getToken, isLoaded, isSignedIn } = useAuth();

   const [entry, setEntry] = useState("");
   const [keys, setKeys] = useState([]);
   const [names, setNames] = useState([]);
   const [selectedLayout, setSelectedLayout] = useState();
   const [layouts, setLayouts] = useState([]);
   const [duration, setDuration] = useState(1);
   const [visibility, setVisibility] = useState(false);

   useEffect(() => {
      // console.log(layouts);
   }, [layouts]);

   useEffect(() => {
      // console.log(names);
   }, [names]);

   const handlers = useMemo(
      () => ({
         getViewerState: () => {
            axios
               .get(SERVER_URL + "/public/getViewerState/" + entryId)
               .then((response) => {
                  if (response && response.data) {
                     setEntry(response.data.state.entry.name);
                     setVisibility(response.data.state.visibility);
                     setDuration(response.data.state.duration);
                  }
               });
         },
         getNames: async () => {
            axios
               .post(
                  SERVER_URL + "/post/getAllNames/",
                  {
                     entryId: entryId,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setNames(response.data);
               });
         },
         getKeys: async () => {
            axios
               .post(
                  SERVER_URL + "/post/getAllKeys/",
                  {
                     entryId: entryId,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setKeys(response.data);
               });
         },
         getLayoutList: async () => {
            axios
               .post(
                  SERVER_URL + "/post/getLayoutList/",
                  {
                     entryId: entryId,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setLayouts(response.data);
               });
         },
         addKey: async (title = "", description = "") => {
            axios
               .post(
                  SERVER_URL + "/post/addKey/",
                  {
                     entryId: entryId,
                     title: title,
                     description: description,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setKeys((prevState) => {
                     let newState = [response.data, ...prevState];
                     return newState;
                  });
               });
         },
         deleteKey: async (id) => {
            axios
               .post(
                  SERVER_URL + "/post/deleteKey/",
                  {
                     id: id,
                     entryId: entryId,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setKeys((prevState) => {
                     let newState = [...prevState];

                     let rowIndex = _.findIndex(newState, function (obj) {
                        if (obj.id == id) {
                           return true;
                        }
                     });

                     newState.splice(rowIndex, 1);
                     return newState;
                  });
               });
         },
         updateKey: async (id, keyNames, layout = null) => {
            axios
               .post(
                  SERVER_URL + "/post/updateKey/",
                  {
                     id: id,
                     entryId: entryId,
                     keyNames: keyNames,
                     layoutId: layout,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setKeys((prevState) => {
                     let newState = [...prevState];

                     let rowIndex = _.findIndex(newState, function (obj) {
                        if (obj.id == response.data.id) {
                           return true;
                        }
                     });

                     newState[rowIndex] = response.data;
                     return newState;
                  });
               });
         },
         saveLayout: async (id, json) => {
            const nameCount = Object.keys(
               _.pickBy(json, (row, key) => {
                  return row.element.type === "name";
               })
            ).length;

            axios
               .post(
                  SERVER_URL + "/post/saveLayout/",
                  {
                     id: id,
                     entryId: entryId,
                     json: json,
                     nameCount: nameCount,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  // console.log(response);

                  handlers.getSelectedLayout(id);
               });
         },
         addLayout: async (id = "null", title = null) => {
            axios
               .post(
                  SERVER_URL + "/post/addLayout/",
                  {
                     entryId: entryId,
                     id: id,
                     title: title,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setSelectedLayout(response.data);
                  handlers.getLayoutList(entryId);
               });
         },
         deleteLayout: async (id) => {
            axios
               .post(
                  SERVER_URL + "/post/deleteLayout/",
                  {
                     entryId: entryId,
                     id: id,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  handlers.getLayoutList(entryId);
                  handlers.clearSelectedLayout();
               });
         },
         getSelectedLayout: async (id) => {
            axios
               .post(
                  SERVER_URL + "/post/getLayout/",
                  {
                     id: id,
                     entryId: entryId,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setSelectedLayout(response.data);
               });
         },
         clearSelectedLayout: () => {
            setSelectedLayout(null);
         },
         handleTextUpdate: (i, nameId, field, value) => {
            setKeys((prevState) => {
               let newState = [...prevState];
               newState[i]["names"][nameId][field] = value;
               return newState;
            });
         },
         handleReorder: async (keys) => {
            setKeys(keys);

            let order = [];

            keys.map((row, i) => {
               order.push({ id: row.id, order: i });
            });

            axios.post(
               SERVER_URL + "/post/updateOrder/",
               {
                  entryId: entryId,
                  keys: order,
               },
               {
                  headers: { Authorization: `Bearer ${await getToken()}` },
               }
            );
         },
         handleLoadKey: async (id, callback) => {
            axios
               .post(
                  SERVER_URL + "/post/updateViewer/",
                  {
                     entryId: entryId,
                     keyId: id,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then(() => {
                  callback();
               });
         },
         updateDuration: async (value) => {
            setDuration(value);

            axios.post(
               SERVER_URL + "/post/updateDuration/",
               {
                  entryId: entryId,
                  duration: value,
               },
               {
                  headers: { Authorization: `Bearer ${await getToken()}` },
               }
            );
         },
         autoTransition: async (value, duration) => {
            setVisibility(value);

            const response = await axios.post(
               SERVER_URL + "/post/updateViewer/",
               {
                  entryId: entryId,
                  visibility: value,
                  duration: duration,
               },
               {
                  headers: { Authorization: `Bearer ${await getToken()}` },
               }
            );

            return response;
         },
         updateVisibility: async (value) => {
            setVisibility(value);

            const response = axios.post(
               SERVER_URL + "/post/updateViewer/",
               {
                  entryId: entryId,
                  visibility: value,
                  duration: 0,
               },
               {
                  headers: { Authorization: `Bearer ${await getToken()}` },
               }
            );

            return response;
         },

         // NAMES
         addName: async (title = "", description = "") => {
            axios
               .post(
                  SERVER_URL + "/post/addName/",
                  {
                     entryId: entryId,
                     title: title,
                     description: description,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setNames((prevState) => {
                     let newState = [response.data, ...prevState];
                     return newState;
                  });
               });
         },
         deleteName: async (id) => {
            axios
               .post(
                  SERVER_URL + "/post/deleteName/",
                  {
                     id: id,
                     entryId: entryId,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setNames((prevState) => {
                     let newState = [...prevState];

                     let rowIndex = _.findIndex(newState, function (obj) {
                        if (obj.id == id) {
                           return true;
                        }
                     });

                     newState.splice(rowIndex, 1);
                     return newState;
                  });
               });
         },
         updateName: async (id, name) => {
            console.log({
               id: id,
               entryId: entryId,
               name: { title: name.title, description: name.description },
            });
            axios
               .post(
                  SERVER_URL + "/post/updateName/",
                  {
                     id: id,
                     entryId: entryId,
                     name: { title: name.title, description: name.description },
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setNames((prevState) => {
                     let newState = [...prevState];

                     let rowIndex = _.findIndex(newState, function (obj) {
                        if (obj.id == response.data.id) {
                           return true;
                        }
                     });

                     newState[rowIndex] = response.data;
                     return newState;
                  });
               });
         },
         handleNameUpdate: (i, field, value) => {
            setNames((prevState) => {
               let newState = [...prevState];
               newState[i][field] = value;
               return newState;
            });
         },
      }),
      []
   );

   return [
      entry,
      keys,
      names,
      layouts,
      selectedLayout,
      duration,
      visibility,
      handlers,
   ];
}
