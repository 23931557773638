import React, { useState } from "react";

import {
   BrowserRouter as Router,
   Routes,
   Route,
   useNavigate,
} from "react-router-dom";

import Home from "./Home";

// CLERK
import { SignIn, SignUp } from "@clerk/clerk-react";

// THEME
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "../application/theme.js";

export default function RoutesPrivate() {
   const navigate = useNavigate();

   return (
      <ThemeProvider theme={theme}>
         <CssBaseline />
         <Routes>
            <Route path='/' element={<Home />} />
            <Route
               path='/sign-in/*'
               element={<SignIn routing='path' path='/sign-in' />}
            />
            <Route
               path='/sign-up/*'
               element={<SignUp routing='path' path='/sign-up' />}
            />
         </Routes>
      </ThemeProvider>
   );
}
