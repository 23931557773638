import Typography from "@mui/material/Typography";

export default function Forbidden() {
   return (
      <>
         <Typography variant='h4' component='div' color='white'>
            Whoops, there has been a problem!
         </Typography>
         <Typography variant='h6' component='div' color='text.light'>
            Either you are not logged in, or you do not have permission to
            access this event!
         </Typography>
      </>
   );
}
