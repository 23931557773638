import { useState, useContext, useMemo, useEffect } from "react";
import { useAuth } from "@clerk/clerk-react";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useAdmin() {
   const { getToken, isLoaded, isSignedIn } = useAuth();

   const [entry, setEntry] = useState({});
   const [entries, setEntries] = useState([]);

   const handlers = useMemo(
      () => ({
         getEntry: async (id) => {
            axios
               .get(SERVER_URL + "/get/entry/" + id, {
                  headers: { Authorization: `Bearer ${await getToken()}` },
               })
               .then((response) => {
                  setEntry(response.data);
               });
         },
         getAllEntries: async () => {
            axios
               .get(SERVER_URL + "/get/entries/", {
                  headers: { Authorization: `Bearer ${await getToken()}` },
               })
               .then((response) => {
                  setEntries(response.data);
               });
         },
         addEntry: async (title) => {
            axios
               .post(
                  SERVER_URL + "/post/add/",
                  {
                     title: title,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setEntries((prevState) => {
                     let newState = [response.data, ...prevState];
                     return newState;
                  });
               });
         },
         updateEntry: async (title, id) => {
            axios
               .post(
                  SERVER_URL + "/post/update/" + id,
                  {
                     title: title,
                  },
                  {
                     headers: { Authorization: `Bearer ${await getToken()}` },
                  }
               )
               .then((response) => {
                  setEntries((prevState) => {
                     let newState = [...prevState];

                     let rowIndex = _.findIndex(newState, function (obj) {
                        if (obj.id == response.data.id) {
                           return true;
                        }
                     });

                     newState[rowIndex] = response.data;
                     return newState;
                  });
               });
         },
         deleteEntry: async (id) => {
            axios
               .get(SERVER_URL + "/get/delete/" + id, {
                  headers: { Authorization: `Bearer ${await getToken()}` },
               })
               .then((response) => {
                  console.log(response);
                  setEntries((prevState) => {
                     let newState = [...prevState];

                     let rowIndex = _.findIndex(newState, function (obj) {
                        if (obj.id == id) {
                           return true;
                        }
                     });

                     newState.splice(rowIndex, 1);
                     return newState;
                  });
               });
         },
      }),
      []
   );

   return [entry, entries, handlers];
}
