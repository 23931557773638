export const labels = {
   borderRadius: { title: "Radius", type: "number" },
   descriptionFontSize: { title: "Description Size", type: "number" },
   descriptionLineHeight: { title: "Description Height", type: "number" },
   height: { title: "Height", type: "number" },
   id: { title: "Element", type: "number" },
   opacity: { title: "Opacity", type: "number" },
   px: { title: "Padding X", type: "number" },
   py: { title: "Padding Y", type: "number" },
   scale: { title: "Image Scale", type: "number" },
   titleFontSize: { title: "Title Size", type: "number" },
   titleLineHeight: { title: "Title Height", type: "number" },
   width: { title: "Width", type: "number" },
   x: { title: "Left", type: "number" },
   y: { title: "Top", type: "number" },
   zIndex: { title: "Order", type: "number" },
   blur: { title: "Blur", type: "number" },
   delay: { title: "Delay", type: "number" },

   backgroundColor: { title: "Background", type: "color" },
   color: { title: "Color", type: "color" },
   descriptionColor: { title: "Description Color", type: "color" },
   titleColor: { title: "Title Color", type: "color" },

   src: { title: "Image", type: "uploader" },

   titleFontFamily: { title: "Title Font", type: "select", renderFont: true },
   descriptionFontFamily: {
      title: "Description Font",
      type: "select",
      renderFont: true,
   },

   type: { title: "Box Type", type: "select" },
   size: { title: "Image Size", type: "select" },
   alignItems: { title: "Alignment", type: "select" },
   justifyContent: { title: "Vertical Alignment", type: "select" },
   titleFontStyle: { title: "Title Font Style", type: "select" },
   descriptionFontStyle: { title: "Description Font Style", type: "select" },
   titleFontWeight: { title: "Title Font Weight", type: "select" },
   descriptionFontWeight: { title: "Description Font Weight", type: "select" },
};

export const inputOptions = {
   delay: { step: 0.1, min: 0, max: 0.9 },
   blur: { step: 1, min: 0, max: 20 },
   opacity: { step: 0.01, min: 0, max: 1 },
   borderRadius: { step: 1, min: 0, max: 50 },
   descriptionLineHeight: { step: 0.1, min: 0.1, max: 5 },
   fontSize: { step: 0.1, min: 8, max: 300 },
   height: { step: 1, min: 0, max: 1080 },
   px: { step: 0.1, min: 0, max: 100 },
   py: { step: 0.1, min: 0, max: 100 },
   rotation: { step: 1, min: 0, max: 360 },
   scale: { step: 0.1, min: 0.1, max: 4 },
   skew: { step: 1, min: 0, max: 45 },
   titleLineHeight: { step: 0.1, min: 0.1, max: 5 },
   width: { step: 1, min: 0, max: 1920 },
   x: { step: 1, min: 0, max: 1920 },
   y: { step: 1, min: 0, max: 1080 },
   zIndex: { step: 1, min: 1, max: 100 },
   titleFontSize: { step: 1, min: 10, max: 100 },
   descriptionFontSize: { step: 1, min: 10, max: 100 },
};

export const selectOptions = {
   animationPresets: [
      { label: "No Animation", value: "none", params: {} },
      {
         label: "Fade In Left",
         value: "fadeInLeft",
         params: { x: -100, opacity: 0 },
      },
      {
         label: "Fade In Right",
         value: "fadeInRight",
         params: { x: 100, opacity: 0 },
      },
      {
         label: "Fade In Top",
         value: "fadeInTop",
         params: { y: -100, opacity: 0 },
      },
      {
         label: "Fade In Bottom",
         value: "fadeInBottom",
         params: { y: 100, opacity: 0 },
      },
      {
         label: "Blur In Left",
         value: "blurInLeft",
         params: { x: -100, opacity: 0, blur: 5 },
      },
      {
         label: "Blur In Right",
         value: "blurInRight",
         params: { x: 100, opacity: 0, blur: 5 },
      },
      {
         label: "Blur In Top",
         value: "blurInTop",
         params: { y: -100, opacity: 0, blur: 5 },
      },
      {
         label: "Blur In Bottom",
         value: "blurInBottom",
         params: { y: 100, opacity: 0, blur: 5 },
      },
      { label: "Move In Left", value: "moveInLeft", params: { x: -1920 } },
      { label: "Move In Right", value: "moveInRight", params: { x: 1920 } },
      { label: "Move In Top", value: "moveInTop", params: { y: -1080 } },
      { label: "Move In Bottom", value: "moveInBottom", params: { y: 1080 } },
   ],
   type: [
      { label: "Image / Color", value: "default" },
      { label: "Name Key", value: "name" },
   ],
   titleFontStyle: [
      { label: "-", value: "unset" },
      { label: "Italic", value: "italic" },
   ],
   titleFontFamily: [
      { label: "Arial", value: "Arial" },
      { label: "Courier", value: "Courier" },
      { label: "Helvetica", value: "Helvetica" },
      { label: "Lucida Grande", value: "Lucida Grande" },
      { label: "Noto Sans", value: "Noto Sans" },
      { label: "Tahoma", value: "Tahoma" },
      { label: "Beleren", value: "Beleren2016-Bold" },
      { label: "Gotham Narrow", value: "GothamNarrow" },
   ],
   descriptionFontStyle: [
      { label: "-", value: "unset" },
      { label: "Italic", value: "italic" },
   ],
   descriptionFontFamily: [
      { label: "Arial", value: "Arial" },
      { label: "Courier", value: "Courier" },
      { label: "Helvetica", value: "Helvetica" },
      { label: "Lucida Grande", value: "Lucida Grande" },
      { label: "Noto Sans", value: "Noto Sans" },
      { label: "Tahoma", value: "Tahoma" },
      { label: "Beleren", value: "Beleren2016-Bold" },
      { label: "Gotham Narrow", value: "GothamNarrow" },
   ],
   titleFontWeight: [
      { label: "Light", value: "100" },
      { label: "Normal", value: "500" },
      { label: "Bold", value: "700" },
      { label: "ExtraBold", value: "900" },
   ],
   descriptionFontWeight: [
      { label: "Light", value: "100" },
      { label: "Normal", value: "500" },
      { label: "Bold", value: "700" },
      { label: "ExtraBold", value: "900" },
   ],
   textAlign: [
      { label: "Left", value: "left" },
      { label: "Center", value: "center" },
      { label: "Right", value: "right" },
   ],
   alignItems: [
      { label: "Left", value: "flex-start" },
      { label: "Center", value: "center" },
      { label: "Right", value: "flex-end" },
   ],
   justifyContent: [
      { label: "Top", value: "flex-start" },
      { label: "Center", value: "center" },
      { label: "Bottom", value: "flex-end" },
   ],
   size: [
      { label: "Pixel 1:1", value: "auto" },
      { label: "Contain", value: "contain" },
   ],
};
