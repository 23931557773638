import React, { useState, useEffect } from "react";
import { SocketContext, initSocket } from "../context/socket";
import { useAuth } from "@clerk/clerk-react";

const SocketProvider = ({ currentSocket, children }) => {
   const { userId } = useAuth();

   return (
      <>
         <SocketContext.Provider
            value={currentSocket ? currentSocket : initSocket(userId)}
         >
            {children}
         </SocketContext.Provider>
      </>
   );
};

export default SocketProvider;
