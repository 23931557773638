import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import axios from "axios";
import _ from "lodash";

import {
   Box,
   Button,
   Grid,
   Stack,
   Typography,
   TextField,
   Slider,
   IconButton,
   Tab,
   Tabs,
} from "@mui/material";

import { motion, Reorder } from "framer-motion";

import Controls from "./controls/Controls";
import ScaleToWidth from "./components/ScaleToWidth.js";
import Viewport from "./components/Viewport";

import KeyList from "./KeyList";
import NameEditor from "./NameEditor";
import LayoutBuilder from "./LayoutBuilder";

import DragIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/DeleteForever";

import useApp from "./hooks/useApp";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function tabProps(index) {
   return {
      id: `tab-${index}`,
   };
}

export default function App() {
   const { entryId } = useParams();

   const [
      entry,
      keys,
      names,
      layouts,
      selectedLayout,
      duration,
      visibility,
      handlers,
   ] = useApp();

   const {
      getViewerState,
      getNames,
      getLayoutList,
      getSelectedLayout,
      getKeys,
      updateDuration,
      updateVisibility,
      autoTransition,
   } = handlers;

   const [showTab, setShowTab] = React.useState(0);
   const [edit, setEdit] = React.useState("logo");
   const [lastUpdate, setLastUpdate] = useState();

   const handleChange = (event, newValue) => {
      if (newValue === 0) {
         getKeys(entryId);
      }

      setShowTab(newValue);
   };

   const updateViewer = () => {
      setLastUpdate(Date.now());
   };

   useEffect(() => {
      getViewerState(entryId);
      getKeys(entryId);
      getNames(entryId);
      getLayoutList(entryId);
   }, []);

   useEffect(() => {
      // getNames(entryId);
   }, [names]);

   return (
      <>
         <Stack spacing={1} sx={{ mb: 6 }} alignItems='flex-start'>
            {entry && (
               <Typography
                  variant='h5'
                  sx={{
                     lineHeight: 2,
                     color: "rgba(255,255,255,.8)",
                     backgroundColor: "rgba(0,0,0,.3)",
                     px: 6,
                     borderRadius: 2,
                  }}
               >
                  {entry}
               </Typography>
            )}

            <Tabs value={showTab} onChange={handleChange} sx={{ mb: 2 }}>
               <Tab label='Keyer' {...tabProps(0)} sx={{ color: "#ccc" }} />
               <Tab label='Names' {...tabProps(1)} sx={{ color: "#ccc" }} />
               <Tab
                  label='Layout Editor'
                  {...tabProps(2)}
                  sx={{ color: "#ccc" }}
               />
            </Tabs>
         </Stack>

         {showTab === 0 && (
            <Stack sx={{ width: "100%", height: "100%" }}>
               <Stack direction='row' sx={{ width: "100%", mb: 4 }} spacing={2}>
                  <Box sx={{ width: "100%" }}>
                     <Stack
                        direction='row'
                        sx={{ width: "100%" }}
                        alignItems='center'
                        justifyContent='space-between'
                     >
                        <Typography
                           sx={{
                              color: "rgba(255,255,255,.3)",
                           }}
                           variant='h6'
                        >
                           PREVIEW
                        </Typography>
                        <Button href={SERVER_URL + "/preview/" + entryId}>
                           PREVIEW URL
                        </Button>
                     </Stack>
                     <Box sx={{ border: "2px solid green" }}>
                        <ScaleToWidth contentWidth={1920} contentHeight={1080}>
                           {(parent) => (
                              <div
                                 className='transparentBG-dark'
                                 style={{
                                    width: 1920,
                                    height: 1080,
                                    overflow: "hidden",
                                    position: "relative",
                                 }}
                              >
                                 <Viewport preview lastUpdate={lastUpdate} />
                              </div>
                           )}
                        </ScaleToWidth>
                     </Box>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                     <Stack
                        direction='row'
                        sx={{ width: "100%" }}
                        alignItems='center'
                        justifyContent='space-between'
                     >
                        <Typography
                           sx={{
                              color: "rgba(255,255,255,.3)",
                           }}
                           variant='h6'
                        >
                           PROGRAM
                        </Typography>
                        <Button href={SERVER_URL + "/viewer/" + entryId}>
                           PROGRAM URL
                        </Button>
                     </Stack>
                     <Box sx={{ border: "2px solid red" }}>
                        <ScaleToWidth contentWidth={1920} contentHeight={1080}>
                           {(parent) => (
                              <div
                                 className='transparentBG-dark'
                                 style={{
                                    width: 1920,
                                    height: 1080,
                                    overflow: "hidden",
                                    position: "relative",
                                 }}
                              >
                                 <Viewport lastUpdate={lastUpdate} />
                              </div>
                           )}
                        </ScaleToWidth>
                     </Box>
                  </Box>
               </Stack>

               <Stack direction='row' sx={{ width: "100%" }} spacing={2}>
                  <Box
                     sx={{
                        backgroundColor: "rgba(0,0,0,.3)",
                        p: 2,
                        color: "#000",
                        width: "70%",
                        height: "100%",
                        overflowY: "auto",
                        overflowX: "hidden",
                        borderRadius: 2,
                     }}
                  >
                     <KeyList
                        keys={keys}
                        names={names}
                        layouts={layouts}
                        updateViewer={updateViewer}
                        handlers={handlers}
                        setShowTab={setShowTab}
                     />
                  </Box>

                  <Stack
                     sx={{
                        backgroundColor: "rgba(0,0,0,.3)",
                        p: 2,
                        color: "#000",
                        width: "30%",
                        height: "100%",
                        overflowY: "auto",
                        overflowX: "hidden",
                        borderRadius: 2,
                     }}
                     spacing={2}
                  >
                     <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                     >
                        <Typography
                           gutterBottom
                           sx={{
                              color: "rgba(255,255,255,.3)",
                           }}
                        >
                           TRANSITION
                        </Typography>
                     </Stack>

                     <Stack direction='row' alignItems='center' spacing={2}>
                        {/* <Button
                           onClick={() =>
                              updateVisibility(!visibility).then(() =>
                                 updateViewer()
                              )
                           }
                           variant={visibility ? "contained" : "outlined"}
                           color={visibility ? "error" : "secondary"}
                        >
                           ON AIR
                        </Button> */}

                        <Button
                           onClick={() =>
                              autoTransition(!visibility, duration).then(() =>
                                 updateViewer()
                              )
                           }
                           variant={visibility ? "contained" : "outlined"}
                           color={visibility ? "error" : "secondary"}
                        >
                           ON AIR
                        </Button>
                     </Stack>

                     <Box sx={{ width: "100%" }}>
                        <Typography
                           gutterBottom
                           sx={{
                              color: "rgba(255,255,255,.3)",
                           }}
                        >
                           Duration: {duration} seconds
                        </Typography>
                        <Slider
                           value={duration}
                           valueLabelDisplay='auto'
                           step={0.1}
                           marks
                           min={0}
                           max={5}
                           onChange={(e) => updateDuration(e.target.value)}
                        />
                     </Box>
                  </Stack>
               </Stack>
            </Stack>
         )}

         {showTab === 1 && <NameEditor names={names} handlers={handlers} />}

         {showTab === 2 && (
            <LayoutBuilder
               layouts={layouts}
               selectedLayout={selectedLayout}
               duration={duration}
               handlers={handlers}
            />
         )}
      </>
   );
}
