import { useState, useContext, useMemo, useEffect } from "react";
import { useParams } from "react-router";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useViewer() {
   const { entryId } = useParams();

   const [layouts, setLayouts] = useState([]);
   const [viewerState, setViewerState] = useState(null);

   useEffect(() => {
      // layouts.length > 0 && console.log("Layouts:", layouts);
   }, [layouts]);

   useEffect(() => {
      // viewerState && console.log("Viewer State:", viewerState);
   }, [viewerState]);

   const handlers = useMemo(
      () => ({
         getLayouts: async () => {
            axios
               .get(SERVER_URL + "/public/getLayouts/" + entryId)
               .then((response) => {
                  setLayouts(response.data);
               });
         },
         getViewerState: () => {
            axios
               .get(SERVER_URL + "/public/getViewerState/" + entryId)
               .then((response) => {
                  if (
                     response &&
                     response.data &&
                     response.data.state &&
                     response.data.state.key &&
                     response.data.state.key.layout
                  ) {
                     const nameElements = _.keys(
                        _.pickBy(
                           response.data.state.key.layout.json,
                           (row, key) => {
                              return row.element.type === "name";
                           }
                        )
                     );

                     response.data.state.key.keyNames = Object.entries(
                        response.data.state.key.keyNames
                     ).map(([key, value], i) => {
                        const matchingName = _.find(
                           response.data.names,
                           function (o) {
                              return o.id == value;
                           }
                        );
                        return {
                           [nameElements[i]]: {
                              title: matchingName ? matchingName.title : "",
                              description: matchingName
                                 ? matchingName.description
                                 : "",
                           },
                        };
                     });

                     response.data.state.key.keyNames = Object.assign(
                        {},
                        ...response.data.state.key.keyNames
                     );

                     setViewerState(response.data.state);
                  }
               });
         },
      }),
      []
   );

   return [viewerState, layouts, handlers];
}
