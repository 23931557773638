import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import axios from "axios";
import _ from "lodash";

import {
   Box,
   Button,
   Grid,
   Stack,
   Typography,
   TextField,
   Slider,
   IconButton,
   Tab,
   Tabs,
} from "@mui/material";

import { motion, Reorder } from "framer-motion";

import SelectMenu from "./controls/SelectMenu";

import DragIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/DeleteForever";

export default function NameEditor({
   keys,
   names,
   layouts,
   selectedLayout,
   duration,
   visibility,
   handlers,
}) {
   const { entryId } = useParams();

   const { addName, updateName, deleteName, handleNameUpdate } = handlers;

   useEffect(() => {
      // getNames();
   }, []);

   return (
      <>
         <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
         >
            <Button color='error' onClick={() => addName()}>
               +ADD NAME
            </Button>
         </Stack>

         {names.length === 0 && (
            <Box
               sx={{
                  p: 2,
                  backgroundColor: "rgba(0,0,0,.2)",
                  color: "rgba(255,255,255,.2)",
               }}
            >
               No names found.
            </Box>
         )}

         {names.length > 0 && (
            <>
               {names.map((name, i) => (
                  <Box key={name.id} value={name}>
                     <Grid
                        key={"key-" + name.id}
                        container
                        alignItems='stretch'
                        sx={{
                           boxSizing: "border-box",
                           width: 400,
                           borderRadius: 1.2,
                           border: "1px solid rgba(0,0,0,.1)",
                           backgroundColor: "rgba(255,255,255,.8)",
                           mb: 1,
                           overflow: "hidden",
                        }}
                     >
                        <Grid item xs={11} sx={{ p: 2 }}>
                           <TextField
                              fullWidth
                              variant='standard'
                              placeholder='Title'
                              value={name.title}
                              onBlur={() => updateName(name.id, name)}
                              onChange={(e) =>
                                 handleNameUpdate(i, "title", e.target.value)
                              }
                           />
                           <TextField
                              fullWidth
                              variant='standard'
                              placeholder='Description'
                              value={name.description}
                              onBlur={() => updateName(name.id, name)}
                              onChange={(e) =>
                                 handleNameUpdate(
                                    i,
                                    "description",
                                    e.target.value
                                 )
                              }
                              sx={{ mb: 1 }}
                           />
                        </Grid>

                        <Grid item xs={1}>
                           <Stack
                              justifyContent='center'
                              sx={{ height: "100%" }}
                           >
                              <IconButton
                                 variant='outlined'
                                 color='error'
                                 size='small'
                                 sx={{ fontSize: "0.4em" }}
                                 onClick={() => deleteName(name.id)}
                              >
                                 <DeleteIcon />
                              </IconButton>
                           </Stack>
                        </Grid>
                     </Grid>
                  </Box>
               ))}
            </>
         )}
      </>
   );
}
