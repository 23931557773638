import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Dashboard from "./Dashboard";

import Application from "../application/App";
import Wrapper from "../../components/Wrapper";

// THEME
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "../application/theme.js";

export default function RoutesPrivate() {
   return (
      <ThemeProvider theme={theme}>
         <CssBaseline />
         <Routes>
            <Route
               path='/'
               element={
                  <Wrapper>
                     <Dashboard />
                  </Wrapper>
               }
            />

            <Route
               path='/:entryId'
               element={
                  <Wrapper>
                     <Application />
                  </Wrapper>
               }
            />
         </Routes>
      </ThemeProvider>
   );
}
