import React, { useState, useEffect, createRef, useMemo } from "react";
import { useParams } from "react-router";
import ReactDOM from "react-dom";
import _ from "lodash";

import { flushSync } from "react-dom";

import Moveable from "react-moveable";

import LayoutElement from "./components/LayoutElement";
import PreviewAnimation from "./components/PreviewAnimation";
import MoveableContainer from "./components/MoveableContainer";
import ScaleToWidth from "./components/ScaleToWidth.js";

import SelectMenu from "./controls/SelectMenu";
import Controls from "./controls/Controls";
import InputSlider from "./controls/InputSlider";
import { labels, inputOptions, selectOptions } from "./controls/Language";

import { initElement, sampleElements } from "./init/Elements.js";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {
   Box,
   Button,
   Grid,
   IconButton,
   Modal,
   Stack,
   TextField,
   Typography,
} from "@mui/material";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   height: "auto",
   textAlign: "center",
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

export default function LayoutBuilder({
   layouts,
   selectedLayout,
   duration,
   handlers,
}) {
   const { entryId } = useParams();

   const {
      getLayoutList,
      getSelectedLayout,
      addLayout,
      deleteLayout,
      saveLayout,
      clearSelectedLayout,
   } = handlers;

   // MODAL
   const [openModal, setOpenModal] = useState(false);

   const handleAddLayoutOpen = () => {
      clearSelectedLayout();
      setLayoutTitle(null);
      setOpenModal(true);
   };
   const handleModalOpen = (name) => {
      selectedLayout && setLayoutTitle(selectedLayout.title);
      setOpenModal(true);
   };
   const handleModalClose = () => {
      setOpenModal(false);
   };

   const handleUpdate = (e) => {
      setLayoutTitle(e.target.value);
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         addLayout(selectedLayout.id, layoutTitle);
         handleModalClose();
      }
   };

   // STATES
   const [layoutTitle, setLayoutTitle] = useState(null);
   const [isUpdated, setIsUpdated] = useState(false);
   const [activeElement, setActiveElement] = useState(0);
   const [currentVariant, setCurrentVariant] = useState("animate");
   const [preview, setPreview] = useState(false);
   const [elements, setElements] = useState();

   const refsById = useMemo(() => {
      if (elements) {
         const refs = {};
         Object.entries(elements).map(([id, el]) => {
            refs[id] = createRef(null);
         });
         return refs;
      }
   }, [elements]);

   useEffect(() => {
      // selectedLayout && getSelectedLayout(selectedLayout.id);
   }, []);

   useEffect(() => {
      if (selectedLayout) {
         setElements(selectedLayout.json);
      }
   }, [selectedLayout]);

   useEffect(() => {
      // console.log(elements);
   }, [elements]);

   const resetInitial = (id) => {
      setIsUpdated(true);

      setElements((prevState) => {
         let newState = { ...prevState };
         newState[id]["initial"] = { ...newState[id]["animate"] };
         delete newState[id]["initial"].delay;

         return newState;
      });
   };

   const addElement = () => {
      setIsUpdated(true);

      setElements((prevState) => {
         let newState = { ...prevState };
         const numElements = _.keys(newState).length;

         newState = {
            ...newState,
            [numElements]: { ...initElement },
         };
         return newState;
      });
   };

   const deleteElement = (element) => {
      setIsUpdated(true);

      setActiveElement(-1);
      setElements((prevState) => {
         let newState = { ...prevState };
         delete newState[element];
         return newState;
      });
   };

   const updateValue = (group, key, value) => {
      setIsUpdated(true);

      setElements((prevState) => {
         let newState = { ...prevState };
         newState[activeElement][group] = {
            ...newState[activeElement][group],
            [key]: value,
         };
         return newState;
      });
   };

   const updateInitialPosition = (value, position = null) => {
      const paramString = _.find(
         selectOptions.animationPresets,
         (o) => o.value === value
      ).params;

      let endPosition = position
         ? { ...elements[activeElement]["animate"], ...position }
         : { ...elements[activeElement]["animate"] };
      let positionParams = {};

      if (paramString.hasOwnProperty("x")) {
         positionParams.x = paramString.x + endPosition.x;
      }

      if (paramString.hasOwnProperty("y")) {
         positionParams.y = paramString.y + endPosition.y;
      }

      if (paramString.hasOwnProperty("width")) {
         positionParams.width = paramString.width + endPosition.width;
      }

      if (paramString.hasOwnProperty("height")) {
         positionParams.height = paramString.height + endPosition.height;
      }

      let otherParams = _.omit(paramString, ["x", "y", "width", "height"]);

      let update = {
         ...endPosition,
         ...positionParams,
         ...otherParams,
      };

      setElements((prevState) => {
         let newState = { ...prevState };
         newState[activeElement]["initial"] = update;
         newState[activeElement]["animation"] = value;
         return newState;
      });

      setIsUpdated(true);
   };

   const handlePositionUpdate = (position, i) => {
      setIsUpdated(true);

      updateInitialPosition(elements[activeElement]["animation"], position);

      setElements((prevState) => {
         let newState = { ...prevState };
         newState[i][currentVariant] = {
            ...newState[i][currentVariant],
            ...position,
         };
         return newState;
      });
   };

   const handlePreview = () => {
      setPreview(true);
      setTimeout(function () {
         setPreview(false);
      }, 3000);
   };

   const handleSaveLayout = () => {
      setIsUpdated(false);

      // console.log("SAVE LAYOUT", selectedLayout, elements);
      saveLayout(selectedLayout.id, elements);
   };

   return (
      <>
         {preview && (
            <PreviewAnimation elements={elements} duration={duration} />
         )}

         <Stack
            alignItems='center'
            sx={{
               position: "relative",
               width: "100%",
               height: "100%",
            }}
         >
            {!selectedLayout && (
               <Box
                  sx={{
                     width: 500,
                     p: 4,
                     backgroundColor: "#efefef",
                     borderRadius: 2,
                  }}
               >
                  <Typography variant='h6' sx={{ mb: 2 }}>
                     Select Layout
                  </Typography>

                  <Stack width='100%' spacing={1}>
                     {_.map(layouts, (layout, i) => {
                        return (
                           <Stack direction='row' alignItems='center'>
                              <Button
                                 onClick={() => getSelectedLayout(layout.id)}
                                 variant='contained'
                                 fullWidth
                                 sx={{ borderRadius: 0 }}
                                 disableElevation
                              >
                                 {layout.title ? layout.title : `Layout ${i}`}
                              </Button>
                              <Button
                                 onClick={() => deleteLayout(layout.id)}
                                 variant='contained'
                                 color='error'
                                 sx={{ borderRadius: 0 }}
                                 disableElevation
                              >
                                 X
                              </Button>
                           </Stack>
                        );
                     })}
                     <Button onClick={handleAddLayoutOpen} color='error'>
                        + Add Layout
                     </Button>
                  </Stack>
               </Box>
            )}

            {selectedLayout && (
               <Stack direction='row' sx={{ width: "100%", height: "100%" }}>
                  {/* EDIT COLUMN */}
                  <Box
                     sx={{
                        backgroundColor: "rgba(255,255,255,.7)",
                        p: 4,
                        color: "#000",
                        width: 400,
                        minWidth: 400,
                        height: "100%",
                        overflowY: "scroll",
                        overflowX: "hidden",
                     }}
                  >
                     <Stack spacing={1} alignItems='center'>
                        <Typography
                           variant='h6'
                           width='100%'
                           sx={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              fontSize: ".8em",
                              color: "#666",
                              textAlign: "left",
                           }}
                        >
                           CURRENT LAYOUT:
                        </Typography>

                        <Stack
                           direction='row'
                           spacing={1}
                           alignItems='center'
                           width='100%'
                        >
                           <SelectMenu
                              value={selectedLayout ? selectedLayout.id : ""}
                              selectOptions={_.map(layouts, (layout, i) => {
                                 return {
                                    label: layout.title
                                       ? layout.title
                                       : `Layout ${i}`,
                                    value: layout.id,
                                 };
                              })}
                              callback={(layoutId) =>
                                 getSelectedLayout(layoutId)
                              }
                           />

                           <IconButton
                              variant='outlined'
                              onClick={handleModalOpen}
                              size='small'
                           >
                              <EditIcon />
                           </IconButton>
                           <IconButton
                              onClick={handleAddLayoutOpen}
                              variant='outlined'
                           >
                              <AddIcon />
                           </IconButton>
                           <IconButton
                              onClick={() => deleteLayout(selectedLayout.id)}
                              variant='outlined'
                           >
                              <DeleteIcon />
                           </IconButton>
                        </Stack>

                        {elements && activeElement >= 0 && (
                           <>
                              <Stack
                                 direction='row'
                                 alignItems='center'
                                 justifyContent='space-between'
                                 width='100%'
                              >
                                 <Typography
                                    variant='h6'
                                    sx={{
                                       position: "relative",
                                       p: 1,
                                       mt: 3,
                                       color: "#fff",
                                       backgroundColor: "rgba(80,0,0,.8)",
                                       textAlign: "center",
                                       borderRadius: 2,
                                       fontWeight: "regular",
                                    }}
                                    width='100%'
                                 >
                                    Selected Element:{" "}
                                    <b
                                       style={{
                                          display: "inline-block",
                                          padding: "0 .3em",
                                          color: "#fff",
                                          backgroundColor: "rgba(0,0,0,.7)",
                                          textAlign: "center",
                                          borderRadius: 4,
                                       }}
                                    >
                                       {activeElement}
                                    </b>
                                 </Typography>
                              </Stack>

                              <Controls
                                 activeElement={activeElement}
                                 currentVariant={currentVariant}
                                 setCurrentVariant={setCurrentVariant}
                                 resetInitial={() =>
                                    resetInitial(activeElement)
                                 }
                                 updateValue={updateValue}
                                 updateInitialPosition={updateInitialPosition}
                                 type={elements[activeElement]["element"].type}
                                 elements={elements[activeElement]}
                              />
                           </>
                        )}
                     </Stack>
                  </Box>

                  {/* VIEWPORT COLUMN */}
                  <Box sx={{ width: "100%" }}>
                     <Stack
                        direction='row'
                        sx={{ width: "100%", mb: 2, px: 2 }}
                        spacing={2}
                     >
                        <Button
                           variant='contained'
                           color={"success"}
                           onClick={handlePreview}
                           sx={{ borderRadius: 0, width: 200 }}
                        >
                           Preview
                        </Button>

                        <Stack
                           direction='row'
                           width='100%'
                           alignItems='center'
                           justifyContent='flex-start'
                           spacing={1}
                           sx={{ pl: 4 }}
                        >
                           <Typography variant='b' color='white'>
                              Elements:
                           </Typography>
                           {_.keys(elements).map((element) => (
                              <Button
                                 variant={
                                    activeElement == element
                                       ? "contained"
                                       : "outlined"
                                 }
                                 onClick={() => setActiveElement(element)}
                                 sx={{ borderRadius: 0 }}
                              >
                                 {element}
                              </Button>
                           ))}
                           <Button
                              onClick={addElement}
                              color='error'
                              size='small'
                              fullWidth
                              sx={{ borderRadius: 0, width: 100 }}
                           >
                              + Add
                           </Button>
                        </Stack>
                     </Stack>
                     <ScaleToWidth contentWidth={1920} contentHeight={1080}>
                        {(parent) => (
                           <div
                              className='transparentBG-dark'
                              style={{
                                 width: 1920,
                                 height: 1080,
                                 overflow: "hidden",
                                 position: "relative",
                              }}
                           >
                              {isUpdated && (
                                 <Box
                                    sx={{
                                       border: "4px solid rgba(255,0,0,.7)",
                                       width: "100%",
                                       height: "100%",
                                       position: "absolute",
                                       top: 0,
                                       left: 0,
                                    }}
                                 >
                                    {isUpdated && (
                                       <Box
                                          sx={{
                                             position: "absolute",
                                             top: 0,
                                             left: 0,
                                             color: "#fff",
                                             p: 2,
                                          }}
                                       >
                                          <Button
                                             onClick={handleSaveLayout}
                                             variant='contained'
                                             color='error'
                                             sx={{
                                                width: 300,
                                             }}
                                             size='large'
                                          >
                                             SAVE CHANGES
                                          </Button>
                                       </Box>
                                    )}
                                 </Box>
                              )}
                              {Object.values(elements).map((element, i) => (
                                 <MoveableContainer
                                    id={i}
                                    variants={element}
                                    currentVariant={currentVariant}
                                    activeElement={activeElement}
                                    setActiveElement={setActiveElement}
                                    position={element[currentVariant]}
                                    setPosition={(position) => {
                                       if (
                                          position != element[currentVariant]
                                       ) {
                                          handlePositionUpdate(position, i);
                                       }
                                    }}
                                    snapElements={Object.values(refsById)}
                                    zIndex={element.style.zIndex}
                                 >
                                    <Box
                                       ref={refsById[i]}
                                       sx={{
                                          width: "100%",
                                          height: "100%",
                                       }}
                                    >
                                       <Box
                                          sx={{
                                             position: "absolute",
                                             top: -20,
                                             left: -20,
                                             width: 20,
                                             height: 20,
                                             borderWidth:
                                                activeElement == i
                                                   ? "2px 0 0 2px"
                                                   : 0,
                                             borderStyle: "solid",
                                             borderColor: "rgba(255,128,0,1)",
                                          }}
                                       />
                                       <Box
                                          sx={{
                                             position: "absolute",
                                             top: -20,
                                             right: -20,
                                             width: 20,
                                             height: 20,
                                             borderWidth:
                                                activeElement == i
                                                   ? "2px 2px 0 0"
                                                   : 0,
                                             borderStyle: "solid",
                                             borderColor: "rgba(255,128,0,1)",
                                          }}
                                       />
                                       <Box
                                          sx={{
                                             position: "absolute",
                                             bottom: -20,
                                             left: -20,
                                             width: 20,
                                             height: 20,
                                             borderWidth:
                                                activeElement == i
                                                   ? "0 0 2px 2px"
                                                   : 0,
                                             borderStyle: "solid",
                                             borderColor: "rgba(255,128,0,1)",
                                          }}
                                       />
                                       <Box
                                          sx={{
                                             position: "absolute",
                                             bottom: -20,
                                             right: -20,
                                             width: 20,
                                             height: 20,
                                             borderWidth:
                                                activeElement == i
                                                   ? "0 2px 2px 0"
                                                   : 0,
                                             borderStyle: "solid",
                                             borderColor: "rgba(255,128,0,1)",
                                          }}
                                       />
                                       <IconButton
                                          color='error'
                                          sx={{
                                             position: "absolute",
                                             bottom: 10,
                                             left: 10,
                                             zIndex: 1000,
                                             backgroundColor: "rgba(255,0,0,1)",
                                             color: "white",
                                             "&:hover": {
                                                backgroundColor:
                                                   "rgba(255,0,0,.8)",
                                             },
                                          }}
                                          onClick={() => deleteElement(i)}
                                          variant='outlined'
                                       >
                                          <DeleteIcon />
                                       </IconButton>

                                       <LayoutElement element={element} />
                                    </Box>
                                 </MoveableContainer>
                              ))}
                           </div>
                        )}
                     </ScaleToWidth>
                     {/* <textarea
                  style={{
                     position: "fixed",
                     marginTop: 20,
                     backgroundColor: "#fff",
                     width: 800,
                     height: 400,
                  }}
               >
                  {JSON.stringify(elements, null, 4)}
               </textarea> */}
                  </Box>
               </Stack>
            )}
         </Stack>

         <Modal
            open={openModal}
            onClose={handleModalClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-upload'
         >
            <Box sx={modalStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Layout Title
               </Typography>
               <Typography id='modal-input' sx={{ pt: 2 }}>
                  <TextField
                     fullWidth
                     key='layoutTitle'
                     id='layoutTitle'
                     label='Title'
                     variant='outlined'
                     value={layoutTitle}
                     onKeyPress={handleKeyPress}
                     onChange={handleUpdate}
                  />

                  <Button
                     variant='contained'
                     onClick={() => {
                        addLayout(
                           selectedLayout ? selectedLayout.id : null,
                           layoutTitle
                        );
                        handleModalClose();
                     }}
                     sx={{ my: 3 }}
                  >
                     SUBMIT
                  </Button>
               </Typography>
            </Box>
         </Modal>
      </>
   );
}
