import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

import {
   Box,
   Button,
   Grid,
   Stack,
   Typography,
   TextField,
   Slider,
} from "@mui/material";

import useViewer from "../hooks/useViewer";
import Element from "./Element";

export default function Viewport({ preview, lastUpdate }) {
   const [viewerState, layouts, { getViewerState }] = useViewer();

   useEffect(() => {
      getViewerState();
   }, [lastUpdate]);

   return (
      <Box
         sx={{
            width: 1920,
            height: 1080,
         }}
      >
         <AnimatePresence mode='wait'>
            {viewerState &&
               viewerState.key.layout &&
               viewerState.key.layout.json && (
                  <div key={lastUpdate}>
                     {Object.entries(viewerState.key.layout.json).map(
                        ([key, element]) => (
                           <Element
                              keyId={viewerState.key.id}
                              elementIndex={key}
                              names={viewerState.key.keyNames}
                              element={element}
                              duration={viewerState.duration}
                              visibility={
                                 preview ? true : viewerState.visibility
                              }
                              preview={preview}
                           />
                        )
                     )}
                  </div>
               )}
         </AnimatePresence>
      </Box>
   );
}
