import React, { useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import useAdmin from "../../hooks/useAdmin";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

// ICONS
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import PreviewIcon from "@mui/icons-material/Preview";
import DashboardIcon from "@mui/icons-material/Dashboard";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   height: "auto",
   textAlign: "center",
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

export default function Entries() {
   const navigate = useNavigate();

   const [
      entry,
      entries,
      { getEntry, getAllEntries, addEntry, deleteEntry, updateEntry },
   ] = useAdmin();

   // STATES
   const [entryTitle, setEntryTitle] = useState();
   const [entryId, setEntryId] = useState();

   // EFFECTS
   useEffect(() => {
      getAllEntries();
   }, []);

   const editEntry = (title, entryId) => {
      setEntryTitle(title);
      setEntryId(entryId);
      handleModalOpen();
   };

   // FUNCTIONS
   const handleUpdate = (e) => {
      setEntryTitle(e.target.value);
   };

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         if (!entryId) {
            addEntry(entryTitle);
         } else {
            updateEntry(entryTitle, entryId);
         }
         handleModalClose();
      }
   };

   const handleEntrySubmit = () => {
      if (!entryId) {
         addEntry(entryTitle);
      } else {
         updateEntry(entryTitle, entryId);
      }
      handleModalClose();
   };

   const handleAddEntryOpen = () => {
      setEntryTitle();
      setEntryId();
      setOpenModal(true);
   };

   // MODAL

   const [openModal, setOpenModal] = useState(false);
   const handleModalOpen = (name) => {
      setOpenModal(true);
   };
   const handleModalClose = () => {
      setOpenModal(false);
   };

   return (
      <>
         <Grid container alignItems='center'>
            <Grid item xs={9}>
               <Typography
                  variant='h4'
                  gutterBottom
                  component='div'
                  color='secondary'
               >
                  Events
               </Typography>
            </Grid>
            <Grid item xs={3}>
               <Button
                  variant='contained'
                  color='primary'
                  onClick={handleAddEntryOpen}
                  fullWidth
               >
                  + ADD EVENT
               </Button>
            </Grid>
         </Grid>
         {entries.length > 0 &&
            entries.map((entry) => (
               <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  spacing={4}
                  sx={{ mt: 4, borderTop: "1px solid #333" }}
               >
                  <Grid item xs={3}>
                     <Typography
                        variant='h6'
                        gutterBottom
                        component='div'
                        color='text.white'
                     >
                        {entry.name ? entry.name : "Unnamed Entry"}
                        <Button
                           color='info'
                           sx={{ ml: 2 }}
                           size='small'
                           onClick={() => editEntry(entry.name, entry.id)}
                           startIcon={<EditIcon />}
                        >
                           EDIT TITLE
                        </Button>
                     </Typography>
                  </Grid>
                  <Grid item xs={8}>
                     <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-start'
                        spacing={2}
                        sx={{ width: "100%" }}
                     >
                        <Button
                           color='warning'
                           onClick={() => navigate("/editor/" + entry.id)}
                           startIcon={<DashboardIcon />}
                           variant='outlined'
                        >
                           Keyer
                        </Button>
                        {/* <Button
                              color='secondary'
                              onClick={() =>
                                 navigate("/admin/client/" + entry.id)
                              }
                              startIcon={<DashboardIcon />}
                              variant='outlined'
                           >
                              Client
                           </Button> */}
                     </Stack>
                  </Grid>
                  <Grid
                     item
                     xs={1}
                     sx={{
                        p: 2,
                     }}
                  >
                     <IconButton
                        aria-label='delete'
                        variant='contained'
                        color='error'
                        size='small'
                        onClick={() => deleteEntry(entry.id)}
                     >
                        <DeleteIcon />
                     </IconButton>
                  </Grid>
               </Grid>
            ))}

         <Modal
            open={openModal}
            onClose={handleModalClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-upload'
         >
            <Box sx={modalStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Entry Title
               </Typography>
               <Typography id='modal-input' sx={{ pt: 2 }}>
                  <TextField
                     fullWidth
                     key='entryTitle'
                     id='entryTitle'
                     label='Entry Title'
                     variant='outlined'
                     value={entryTitle}
                     onKeyPress={handleKeyPress}
                     onChange={handleUpdate}
                  />

                  <Button
                     variant='contained'
                     onClick={handleEntrySubmit}
                     sx={{ my: 3 }}
                  >
                     SUBMIT
                  </Button>
               </Typography>
            </Box>
         </Modal>
      </>
   );
}
