import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";

import {
   FormatAlignLeft,
   FormatAlignCenter,
   FormatAlignRight,
   VerticalAlignBottom,
   VerticalAlignCenter,
   VerticalAlignTop,
} from "@mui/icons-material";

const MenuIcons = ({ section, name, value }) => {
   switch (section) {
      case "text":
         switch (name) {
            case "alignItems":
               switch (value) {
                  case "flex-start":
                     return <FormatAlignLeft />;
                     break;
                  case "center":
                     return <FormatAlignCenter />;
                     break;
                  case "flex-end":
                     return <FormatAlignRight />;
                     break;
               }
               break;
            case "justifyContent":
               switch (value) {
                  case "flex-start":
                     return <VerticalAlignTop />;
                     break;
                  case "center":
                     return <VerticalAlignCenter />;
                     break;
                  case "flex-end":
                     return <VerticalAlignBottom />;
                     break;
               }
               break;
         }
         break;
   }
};

export default function SelectMenu({
   label,
   value,
   group = null,
   name = null,
   selectOptions,
   renderFont,
   callback,
}) {
   const handleSelectChange = (e) => {
      callback(e.target.value);
   };

   return (
      <Stack sx={{ width: "100%", pt: 1 }}>
         <FormControl fullWidth size='small'>
            <InputLabel>{label}</InputLabel>
            {selectOptions && selectOptions.length > 0 && (
               <Select
                  value={value}
                  onChange={handleSelectChange}
                  sx={{ width: "100%" }}
                  size='small'
                  input={<OutlinedInput label={label} />}
               >
                  {selectOptions.map((row, i) => (
                     <MenuItem
                        key={"selectOption" + i}
                        value={row.value}
                        sx={{
                           fontFamily: renderFont ? row.value : "unset",
                        }}
                     >
                        <Stack direction='row' alignItems='center' spacing={2}>
                           <Box sx={{ color: "#888" }}>
                              <MenuIcons
                                 section={group}
                                 name={name}
                                 value={row.value}
                              />
                           </Box>
                           <div>{row.label}</div>
                        </Stack>
                     </MenuItem>
                  ))}
               </Select>
            )}
            {selectOptions.length === 0 && <>Loading...</>}
         </FormControl>
      </Stack>
   );
}
