import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { motion } from "framer-motion";

import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";

function ElementContainer({ elementIndex, element, names }) {
   return (
      <>
         {element["element"].type == "name" && (
            <Stack
               sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  color: "#000",
                  zIndex: 100,
               }}
               alignItems={element.text.alignItems}
               justifyContent={element.text.justifyContent}
            >
               <Box
                  sx={{
                     pl: element.text.px,
                     fontSize: element.text.titleFontSize,
                     lineHeight: element.text.titleLineHeight,
                     fontFamily: element.text.titleFontFamily,
                     fontWeight: element.text.titleFontWeight,
                     fontStyle: element.text.titleFontStyle,
                     color: element.text.titleColor,
                  }}
               >
                  {names && names[elementIndex]
                     ? names[elementIndex].title
                     : "Sample Name"}
               </Box>
               <Box
                  sx={{
                     pl: element.text.px,
                     fontSize: element.text.descriptionFontSize,
                     lineHeight: element.text.descriptionLineHeight,
                     fontFamily: element.text.descriptionFontFamily,
                     fontWeight: element.text.descriptionFontWeight,
                     fontStyle: element.text.descriptionFontStyle,
                     color: element.text.descriptionColor,
                  }}
               >
                  {names && names[elementIndex]
                     ? names[elementIndex].description
                     : "Sample Description"}
               </Box>
            </Stack>
         )}

         <Box
            sx={{
               position: "absolute",
               top: 0,
               left: 0,
               width: "100%",
               height: "100%",
               background: `url(${element.image.src}) center right`,
               backgroundRepeat: "no-repeat",
               backgroundSize: element.image.size,
               transform: `scale(${element.image.scale})`,
            }}
         ></Box>
      </>
   );
}

export default function Element({
   keyId,
   elementIndex,
   currentVariant = "animate",
   names,
   element,
   duration,
   visibility,
   preview = false,
}) {
   return (
      <>
         {preview && (
            <div
               key={keyId + "-" + elementIndex}
               style={{
                  position: "absolute",
                  zIndex: element.style.zIndex,
                  outline: "40px solid transparent",
                  overflow: "hidden",
                  backgroundColor: element.color.backgroundColor,

                  left: element.animate.x,
                  top: element.animate.y,
                  width: element.animate.width,
                  height: element.animate.height,
                  opacity: element.animate.opacity,
                  borderRadius: element.animate.borderRadius,
                  filter: `blur(${element.animate.blur}px)`,
               }}
               className='element'
            >
               <ElementContainer
                  elementIndex={elementIndex}
                  element={element}
                  names={names}
               />
            </div>
         )}

         {!preview && visibility && (
            <motion.div
               key={keyId + "-" + elementIndex}
               style={{
                  position: "absolute",
                  zIndex: element.style.zIndex,
                  outline: "40px solid transparent",
                  overflow: "hidden",
                  backgroundColor: element.color.backgroundColor,
               }}
               initial={
                  element.initial.hasOwnProperty("x")
                     ? {
                          x: element.initial.x,
                          y: element.initial.y,
                          width: element.initial.width,
                          height: element.initial.height,
                          opacity: element.initial.opacity,
                          borderRadius: element.initial.borderRadius,
                          filter: `blur(${element.initial.blur}px)`,
                       }
                     : {
                          x: element.animate.x,
                          y: element.animate.y,
                          width: element.animate.width,
                          height: element.animate.height,
                          opacity: element.animate.opacity,
                          borderRadius: element.animate.borderRadius,
                          filter: `blur(${element.animate.blur}px)`,
                       }
               }
               animate={{
                  x: element.animate.x,
                  y: element.animate.y,
                  width: element.animate.width,
                  height: element.animate.height,
                  opacity: element.animate.opacity,
                  borderRadius: element.animate.borderRadius,
                  filter: `blur(${element.animate.blur}px)`,
                  transition: {
                     ease: "easeOut",
                     delay: duration * element.animate.delay,
                     duration: duration - duration * element.animate.delay,
                  },
               }}
               exit={
                  element.initial.hasOwnProperty("x")
                     ? {
                          x: element.initial.x,
                          y: element.initial.y,
                          width: element.initial.width,
                          height: element.initial.height,
                          opacity: element.initial.opacity,
                          borderRadius: element.initial.borderRadius,
                          filter: `blur(${element.initial.blur}px)`,
                          transition: {
                             ease: "easeIn",
                             duration: duration / 2,
                          },
                       }
                     : {
                          x: element.animate.x,
                          y: element.animate.y,
                          width: element.animate.width,
                          height: element.animate.height,
                          opacity: element.animate.opacity,
                          borderRadius: element.animate.borderRadius,
                          filter: `blur(${element.animate.blur}px)`,
                          transition: {
                             ease: "easeIn",
                             duration: 0,
                          },
                       }
               }
               className='element'
            >
               <ElementContainer
                  elementIndex={elementIndex}
                  element={element}
                  names={names}
               />
            </motion.div>
         )}
      </>
   );
}
