import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router";

import Viewport from "../application/components/Viewport";

// SOCKET.IO
import { socket } from "../../context/socket";

export default function Preview() {
   const { entryId } = useParams();

   const [lastUpdate, setLastUpdate] = useState();

   const updateViewer = () => {
      setLastUpdate(Date.now());
   };

   useEffect(() => {
      socket.emit("joinRoom", entryId, (response) => {
         console.log(response);
      });
      document.body.classList.add("noScroll");
   }, []);

   useEffect(() => {
      socket.on("refreshViewer", () => {
         window.location.reload(false);
      });

      socket.on("updateViewer", (json) => {
         updateViewer();
      });
   }, [socket]);

   return <Viewport preview lastUpdate={lastUpdate} />;
}
