import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import axios from "axios";
import _ from "lodash";

import {
   Box,
   Button,
   Grid,
   Stack,
   Typography,
   TextField,
   Slider,
   IconButton,
   Tab,
   Tabs,
} from "@mui/material";

import { motion, Reorder } from "framer-motion";

import SelectMenu from "./controls/SelectMenu";

import DragIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/DeleteForever";

export default function KeyList({
   keys,
   names,
   layouts,
   updateViewer,
   handlers,
   setShowTab,
}) {
   const { entryId } = useParams();

   const {
      addKey,
      updateKey,
      deleteKey,
      handleReorder,
      handleLoadKey,
      updateVisibility,
   } = handlers;

   //    useEffect(() => {
   //       console.log(keys);
   //    }, [keys]);
   //
   //    useEffect(() => {
   //       console.log(names);
   //    }, [names]);
   //
   //    useEffect(() => {
   //       updateViewer();
   //    }, [visibility]);

   return (
      <>
         <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
         >
            <Typography
               gutterBottom
               sx={{
                  color: "rgba(255,255,255,.3)",
               }}
            >
               KEYS
            </Typography>

            {keys.length > 0 && (
               <Button color='error' onClick={() => addKey()}>
                  +ADD KEY
               </Button>
            )}
         </Stack>

         {keys.length === 0 && (
            <Stack
               sx={{
                  py: 4,
                  backgroundColor: "rgba(0,0,0,.2)",
                  color: "rgba(255,255,255,.2)",
               }}
               spacing={2}
               alignItems='center'
            >
               <span>No keys found.</span>
               <Button
                  color='error'
                  variant='outlined'
                  onClick={() => addKey()}
               >
                  Add a Key
               </Button>
            </Stack>
         )}

         {keys.length > 0 && (
            <Reorder.Group
               key={keys}
               axis='y'
               values={keys}
               onReorder={(keys) => handleReorder(keys)}
               style={{ overflow: "hidden" }}
            >
               {keys.map((row, keyIndex) => (
                  <Reorder.Item key={row.id} value={row}>
                     <Grid
                        key={"key-" + row.id}
                        container
                        alignItems='stretch'
                        sx={{
                           boxSizing: "border-box",
                           width: "100%",
                           borderRadius: 1.2,
                           border: "1px solid rgba(0,0,0,.1)",
                           backgroundColor: "rgba(255,255,255,.8)",
                           mb: 1,
                           overflow: "hidden",
                        }}
                     >
                        <Grid item xs={9}>
                           <Stack
                              alignItems='center'
                              justifyContent='center'
                              direction='row'
                              sx={{ height: "100%" }}
                              spacing={2}
                           >
                              <Box
                                 sx={{
                                    height: "100%",
                                    backgroundColor: "rgba(0,0,0,.2)",
                                    color: "#fff",
                                 }}
                              >
                                 <Stack
                                    alignItems='center'
                                    justifyContent='center'
                                    sx={{
                                       height: "100%",
                                    }}
                                 >
                                    <DragIcon />
                                 </Stack>
                              </Box>
                              <Stack
                                 direction='row'
                                 sx={{ py: 1, width: "100%", height: "100%" }}
                                 spacing={1}
                                 alignItems='flex-start'
                              >
                                 <Stack
                                    sx={{
                                       width: !row.layout ? "50%" : "100%",
                                       height: "100%",
                                    }}
                                    spacing={1}
                                    alignItems='center'
                                    justifyContent='center'
                                 >
                                    {!row.layout && <b>Select a Layout</b>}

                                    {row.layout && names.length == 0 && (
                                       <Box sx={{ width: "100%" }}>
                                          <b>
                                             <Button
                                                onClick={() => setShowTab(1)}
                                             >
                                                + Add Name
                                             </Button>
                                          </b>
                                       </Box>
                                    )}

                                    {row.layout &&
                                       names.length > 0 &&
                                       _.times(row.layout.nameCount, (n) => (
                                          <SelectMenu
                                             label={"Name " + (n + 1)}
                                             value={
                                                row.keyNames && row.keyNames[n]
                                                   ? row.keyNames[n]
                                                   : ""
                                             }
                                             selectOptions={_.map(
                                                names,
                                                (name, i) => {
                                                   return {
                                                      label: name.title
                                                         ? name.title
                                                         : "No Name " + i,
                                                      value: name.id,
                                                   };
                                                }
                                             )}
                                             callback={(nameId) => {
                                                const updateNames = {
                                                   ...row.keyNames,
                                                   [n]: nameId,
                                                };

                                                updateKey(
                                                   row.id,
                                                   updateNames,
                                                   row.layoutId
                                                );
                                             }}
                                          />
                                       ))}
                                 </Stack>

                                 {layouts.length == 0 && (
                                    <Box sx={{ width: "100%" }}>
                                       <b>
                                          <Button onClick={() => setShowTab(2)}>
                                             + Add Layout
                                          </Button>
                                       </b>
                                    </Box>
                                 )}
                                 {layouts.length > 0 && (
                                    <Box sx={{ width: "100%" }}>
                                       <SelectMenu
                                          label='Layout'
                                          value={row.layoutId}
                                          selectOptions={_.map(
                                             layouts,
                                             (layout, i) => {
                                                return {
                                                   label: layout.title
                                                      ? layout.title
                                                      : "Layout " + i,
                                                   value: layout.id,
                                                };
                                             }
                                          )}
                                          callback={(layoutId) =>
                                             updateKey(
                                                row.id,
                                                row.names,
                                                layoutId
                                             )
                                          }
                                       />
                                    </Box>
                                 )}
                              </Stack>
                           </Stack>
                        </Grid>

                        <Grid item xs={1}>
                           <Stack
                              justifyContent='center'
                              sx={{ height: "100%" }}
                           >
                              <IconButton
                                 variant='outlined'
                                 color='error'
                                 size='small'
                                 sx={{ fontSize: "0.4em" }}
                                 onClick={() => deleteKey(row.id, entryId)}
                              >
                                 <DeleteIcon />
                              </IconButton>
                           </Stack>
                        </Grid>

                        <Grid item xs={2}>
                           <Button
                              sx={{ height: "100%" }}
                              variant='contained'
                              color='secondary'
                              onClick={() =>
                                 handleLoadKey(row.id, updateViewer)
                              }
                              fullWidth
                           >
                              LOAD
                           </Button>
                        </Grid>
                     </Grid>
                  </Reorder.Item>
               ))}
            </Reorder.Group>
         )}
      </>
   );
}
