import { useState, useEffect, useRef } from "react";

import Element from "./Element";
import ScaleToWidth from "./ScaleToWidth.js";

import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";

export default function PreviewAnimation({ elements, duration }) {
   return (
      <Stack
         sx={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(40,40,40,1)",
            position: "fixed",
            zIndex: 1000,
         }}
         alignItems='center'
         justifyContent='center'
      >
         <Box sx={{ width: 1200 }}>
            <ScaleToWidth contentWidth={1920} contentHeight={1080}>
               {(parent) => (
                  <div
                     className='previewContainer transparentBG-dark'
                     style={{
                        width: 1920,
                        height: 1080,
                        overflow: "hidden",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        border: "1px solid #ccc",
                        zIndex: 100,
                     }}
                  >
                     {Object.entries(elements).map(([key, element]) => (
                        <Element
                           keyId={"keyId-" + key}
                           elementIndex={key}
                           element={element}
                           duration={duration}
                           visibility={true}
                        />
                     ))}
                  </div>
               )}
            </ScaleToWidth>
         </Box>
      </Stack>
   );
}
