// import React, { useContext } from "react";
//
// const userId = useContext(UserIdContext);
//
// export const SocketContext = React.createContext();
//
// export const socket = io.connect(SERVER_URL, {
//    auth: {
//       token: "test",
//    },
// });

import React, { createContext } from "react";
import { io } from "socket.io-client";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const SocketContext = createContext();

export let socket;

export const initSocket = (userId) => {
   socket = io.connect(SERVER_URL, {
      auth: {
         token: userId,
      },
   });

   return socket;
};
